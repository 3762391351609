import React from "react";
import PropTypes from "prop-types";

const Video = ({ channel, isOpen, videoId, setOpen }) => {
    if (typeof window === "undefined") return null;
};

Video.propTypes = {
    isOpen: PropTypes.bool,
    videoId: PropTypes.string,
    setOpen: PropTypes.func,
};

export default Video;
